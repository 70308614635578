import { postInfo } from './api';

export default {
  // 库存货品列表管理
  getStorageList(data) {
    return postInfo('/storage/list', data);
  },

  // 货仓列表
  getWarehouseList(data) {
    return postInfo('/storage/warehouse/list', data);
  },

  // 添加货仓
  addWarehouse(data) {
    return postInfo('/storage/warehouse/add', data);
  },

};
